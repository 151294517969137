<template>
  <div class='container'>

    <van-tabs v-model="active" animated swipeable sticky title-inactive-color="#232323" color="#17AD84" title-active-color="#17AD84">


      <van-tab :title="`未使用(${unusedFilter.length})`">
        <!-- 如果有优惠券 -->
        <div class="unused" v-if="unusedFilter.length">
          <div v-for="(item,i) in unusedFilter" class="circulation" :key="i">
            <gold :data="item" @click="$router.push('/home')" :state="1" txt="立即使用" />
          </div>
        </div>

        <!-- 暂无数据 -->

        <van-empty description="暂无优惠券" v-else />

      </van-tab>


      <van-tab :title="`已使用(${havebeenusedFilter.length})`">
        <!-- 已使用优惠券 -->
        <div class="unused" v-if="havebeenusedFilter.length">
          <div v-for="(item,i) in havebeenusedFilter" class="circulation" :key="i">
            <gold :data="item" opacity :state="2" />
          </div>
        </div>
        <!-- 暂无数据 -->
        <van-empty description="暂无数据" v-else />
      </van-tab>


      <van-tab :title="`已过期(${pastdueFilter.length})`">
        <!-- 已过期优惠券 -->
        <div class="unused" v-if="pastdueFilter.length">
          <div v-for="(item,i) in pastdueFilter" class="circulation" :key="i">
            <gold :data="item" opacity :state="3" />
          </div>
        </div>
        <!-- 暂无数据 -->
        <van-empty description="暂无数据" v-else />

      </van-tab>


    </van-tabs>

  </div>
</template>

<script>
import gold from '@/components/Coupon/Coupon'
export default {
  // Vue 实例的数据对象
  data() {
    return {
      active: 0,
      obviously: true,
      list: []
    }
  },
  components: {
    gold
  },
  computed: {
    unusedFilter() {
      if (this.list.length == 0) {
        return this.list
      } else {
        return this.list.filter((value) => {
          if (value.status === 1) {
            return value
          }
        })
      }
    },
    havebeenusedFilter() {
      if (this.list.length == 0) {
        return this.list
      } else {
        return this.list.filter((value) => {
          if (value.status === 2) {
            return value
          }
        })
      }
    },
    pastdueFilter() {
      if (this.list.length == 0) {
        return this.list
      } else {
        return this.list.filter((value) => {
          if (value.status === 3 || value.status == 0) {
            return value
          }
        })
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      axios.get('get_my_coupon').then((res) => {
        this.list = res.data
        console.log(res)
      })
    }
  }
}
</script>

<style lang='less' scoped>
.container {
  background-color: #f6f6f6;
  min-height: 100vh;
  height: 100%;
  .unused {
    padding: 20.5px 15px;

    .circulation {
      background-color: #fff;
      margin-bottom: 15.5px;
      // height: 99px;
    }
  }
}
/deep/.van-tabs {
  height: 100%;
}
/deep/.van-tabs__content--animated {
  min-height: calc(100% - 44px);
}
</style>
